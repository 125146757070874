<template>
  <div>
    <v-data-table v-if="!isLoading" :headers="headers" :items="plans">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" class="mr-3" v-on="on" @click="editPlan(item)"> mdi-pencil </v-icon>
          </template>
          Módosítás
        </v-tooltip>
        <v-tooltip v-if="!item.companiesCount" bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" v-on="on" @click="openDeleteConfirmator(item)">mdi-delete</v-icon>
          </template>
          Törlés
        </v-tooltip>
      </template>
    </v-data-table>

    <v-skeleton-loader type="table" v-else></v-skeleton-loader>
    <ConfirmDialog :confirm-handler="deleteConfirmHandler"></ConfirmDialog>
    <PlanDialog></PlanDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DELETE_PLAN } from '@/store/types'
const ConfirmDialog = () => import('@/components/Global/ConfirmDialog.vue')
const PlanDialog = () => import('@/components/Plans/PlanDialog.vue')

export default {
  name: 'PlansTable',
  components: {
    ConfirmDialog,
    PlanDialog
  },
  data() {
    return {
      headers: [
        {
          text: '#',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Név',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Ár/Fő',
          align: 'start',
          value: 'price_per_person'
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      deleteConfirmHandler: {}
    }
  },
  computed: {
    ...mapState({
      plans: state => state.plans.plans
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  methods: {
    openDeleteConfirmator(resource) {
      this.deleteConfirmHandler = {
        handler: () => this.deleteResource(resource),
        title: `${resource.name} csomag törlése`
      }
      this.$modal.show('confirm-dialog')
    },
    async deleteResource(resource) {
      await this.$store.dispatch(DELETE_PLAN, resource.id)
      this.$modal.hide('confirm-dialog')
    },
    editPlan(plan) {
      this.$modal.show('plan-dialog', {
        plan
      })
    }
  }
}
</script>
